































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Breadcrumb from '@/components/Breadcrumb.vue'
import MediaObj from '@/interfaces/MediaObj'

@Component({
  components: {
    Breadcrumb
  }
})
export default class Media extends Vue {
  mediaList: MediaObj[] = [
    {
      id: 1,
      categoryClass: 'category-twitter',
      categoryName: 'X（旧Twitter）',
      categoryImgUrl: '/img/icon_x_black.png',
      list: [
        {
          id: 1,
          url: 'https://twitter.com/stock_point0912',
          description: '',
          imgUrl: '/img/capture_twitter_sp0912.png'
        },
        {
          id: 2,
          url: 'https://twitter.com/stock_connect',
          description: '',
          imgUrl: '/img/capture_twitter_spc.png'
        },
        {
          id: 3,
          url: 'https://twitter.com/SPMOFFICIAL2021',
          description: '',
          imgUrl: '/img/capture_twitter_spm.png'
        },
        {
          id: 4,
          url: 'https://twitter.com/SP_for_CHEER',
          description: '',
          imgUrl: '/img/capture_twitter_spcheer.png'
        },
        {
          id: 5,
          url: 'https://twitter.com/stock_point',
          description: '',
          imgUrl: '/img/capture_twitter_sp.png'
        },
        {
          id: 6,
          url: 'https://twitter.com/aisarekigyo_SP',
          description: '',
          imgUrl: '/img/capture_twitter_aisare.png'
        }
      ]
    },
    {
      id: 2,
      categoryClass: 'category-youtube',
      categoryName: 'Youtube',
      categoryImgUrl: '/img/youtube_social_circle_red.png',
      list: [
        {
          id: 1,
          url: 'https://www.youtube.com/channel/UCWO-7mHSBHkY8XQHnR4xLlw/videos',
          description: '',
          imgUrl: '/img/capture_youtube.png'
        }
      ]
    },
    {
      id: 3,
      categoryClass: 'category-instagram',
      categoryName: 'Instagram',
      categoryImgUrl: '/img/icon_insta.svg',
      list: [
        {
          id: 1,
          url: 'https://www.instagram.com/stock_point_official',
          description: '',
          imgUrl: '/img/capture_insta.png'
        }
      ]
    },
    {
      id: 4,
      categoryClass: 'category-note',
      categoryName: 'note',
      categoryImgUrl: '/img/icon_note.svg',
      list: [
        {
          id: 1,
          url: 'https://note.com/stock_point/',
          description: '',
          imgUrl: '/img/capture_note.png'
        }
      ]
    },
    {
      id: 5,
      categoryClass: 'category-mailMagazine',
      categoryName: 'メールマガジン',
      categoryImgUrl: '/img/icon_mail.svg',
      list: [
        {
          id: 1,
          url: '',
          description: '※毎週週末',
          imgUrl: '/img/capture_mail_magazine.png'
        }
      ]
    }
  ]
}
